import { useElements, useStripe, CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { CreateTokenCardData, StripeCardElement, StripeCardNumberElement, Token } from '@stripe/stripe-js';

type GetStripeToken = (opts: { name: string | undefined }) => Promise<Token | undefined>;

interface CreateStripeToken {
	({
		cardInfo,
		data,
	}: {
		cardInfo: StripeCardElement | StripeCardNumberElement;
		data: CreateTokenCardData;
	}): Promise<Token | undefined>;
}

interface GetCardType {
	// eslint-disable-next-line @rushstack/no-new-null
	(): StripeCardElement | null | undefined;
}

type GetPaymentMethodId = () => Promise<string | undefined>;

type UseStripeHelp = () => {
	getCardElement: GetCardType;
	getStripeToken: GetStripeToken;
	getPaymentMethodId: GetPaymentMethodId;
	createStripeToken: CreateStripeToken;
};

export const useStripeHelp: UseStripeHelp = () => {
	const stripe = useStripe();
	const elements = useElements();

	const getCardElement: GetCardType = () => {
		return elements?.getElement(CardElement);
	};

	const createStripeToken: CreateStripeToken = async ({ cardInfo, data }) => {
		if (!stripe || !cardInfo) {
			throw new Error(`missing_card_info`);
		}
		const { error, token } = await stripe.createToken(cardInfo, data);
		if (error || !token) throw new Error(error?.code);
		return token;
	};

	const getStripeToken: GetStripeToken = async ({ name }) => {
		const cardElement = getCardElement();

		if (cardElement) {
			return createStripeToken({
				cardInfo: cardElement,
				data: { name },
			});
		}
	};

	const getPaymentMethodId: GetPaymentMethodId = async () => {
		if (!stripe || !elements) {
			return;
		}
		const paymentElement = elements.getElement(PaymentElement);
		if (!paymentElement) return;

		await elements.submit();
		const { paymentMethod } = await stripe.createPaymentMethod({
			elements,
		});
		return paymentMethod?.id;
	};

	return {
		getStripeToken,
		getPaymentMethodId,
		getCardElement,
		createStripeToken,
	};
};
